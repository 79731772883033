"use client";

import { useEffect, useState } from "react";
import * as React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { type PageContent } from "@/utils/i18n";
import { SEARCH_PARAMS_NEWSLETTER_OPT_OUT } from "@/utils/queryParams";
import { trpc } from "@/utils/TrpcProvider";
import { useSession } from "next-auth/react";
import { type ThemeProviderProps } from "next-themes/dist/types";
import { SimpleDialog } from "../ui/dialog";
import { Icon } from "../ui/Icon";
import { Text } from "../ui/Text";
import { SignIn } from "./SignIn";
import { VerifyingAuthScreen } from "./VerifyingAuthScreen";
const t = {
  logInOrSignUp: {
    no: "Logg inn eller registrer deg",
    en: "Log in or sign up",
    dk: "Log ind eller opret en konto"
  },
  default: {
    1: {
      en: "Help 10x the candidate experience for your network",
      no: "Hjelp 10x kandidatopplevelsen for nettverket ditt",
      dk: "Hjælp 10x kandidatoplevelsen for dit netværk",
      subtitle: {
        en: "Imagine getting a testimonial instead of writing cover letters ❤️",
        no: "Forestill deg å få en anbefaling i stedet for å skrive søknad ❤️",
        dk: "Forestil dig at få en anbefaling i stedet for at skrive ansøgning ❤️"
      }
    },
    2: {
      en: "Earn a reward for a successful hire - if you want to",
      no: "Tjen en belønning for en vellykket ansettelse - hvis du vil",
      dk: "Tjen en belønning for en vellykket ansettelse - hvis du vil",
      subtitle: {
        en: "We have paid millions in referral rewards, but some also opt out",
        no: "Vi har betalt millioner i henvisnings, men noen velger også å melde seg ut",
        dk: "Vi har betalt millioner i henvisnings, men noen velger også å melde seg ut"
      }
    },
    3: {
      en: "Trusted by companies across various industries and roles",
      no: "Stolt samarbeidspartner med selskaper innenfor en rekke bransjer og roller",
      dk: "Stolt partner med virksomheder inden for en række brancher og roller",
      subtitle: {
        en: "Sopra Steria, Ignite, Wilhelmsen, PwC, Brødrene Dahl. We have help fill roles from office managers to healthcare specialist, and even CTOs.",
        no: "Sopra Steria, Ignite, Wilhelmsen, PwC, Brødrene Dahl. Vi har hjulpet med å fylle roller fra kontorledere til helsepersonell og til og med CTOer.",
        dk: "Sopra Steria, Ignite, Wilhelmsen, PwC, Brødrene Dahl. Vi har hjulpet med at udfylde roller fra kontorledere til sundhedspersonale og endda CTOer."
      }
    }
  },
  acceptVouch: {
    1: {
      en: "Don't worry, no long forms and cover letters required",
      no: "Slapp av, det kreves ingen søknadsbrev",
      dk: "Slap af, ingen lange formularer og ansøgninger kræves",
      subtitle: {
        en: "All you have to do is to confirm interest and add your LinkedIn or CV",
        no: "Alt du trenger å gjøre er å bekrefte interesse og legge til LinkedIn eller CV",
        dk: "Alt, hvad du skal gøre, er at bekræfte interesse og tilføje din LinkedIn eller CV"
      }
    },
    2: {
      en: "We take privacy seriously",
      no: "Vi tar personvern på alvor",
      dk: "Vi tager privatliv alvorligt",
      subtitle: {
        en: "We only update the voucher when you are hired or out of process, and we won't tell them why. We also have a strict data policy",
        no: "Vi gir ikke detaljert informasjon om prosessen til tipser eller noen andre.",
        dk: "Vi opdaterer kun voucheren, når du er ansat eller ude af processen, og vi vil ikke fortælle dem hvorfor. Vi har også en streng databeskyttelsespolitik"
      }
    },
    3: {
      en: "The first vouch you accept for a role may be eligble for a reward",
      no: "Den første anbefalingen du godtar for en rolle kan gi belønning til tipser",
      dk: "Den første anbefaling, du accepterer til en rolle, kan være berettiget til en belønning",
      subtitle: {
        en: "We see it as a win-win-win. Everyone is rewarded, especially the voucher.",
        no: "Men er det flere som anbefaler deg, så vil kun den første du godtar få belønning.",
        dk: "Vi ser det som en win-win-win. Alle bliver belønnet, især voucheren."
      }
    }
  },
  business: {
    1: {
      en: "Attract passive talents and hire focused on quality, not just quantity",
      no: "Tiltrekk passive talenter og ansett fokusert på kvalitet, ikke bare kvantitet",
      dk: "Tiltræk passive talenter og ansæt fokuseret på kvalitet, ikke kun kvantitet",
      subtitle: {
        en: "Did you know 7 in 10 talents don't actively apply? We will teach you to use your network better to capture those hidden talent. ",
        no: "Visste du at 7 av 10 talenter ikke aktivt søker? Vi vil lære deg å bruke nettverket ditt bedre for å fange opp disse skjulte talentene.",
        dk: "Vidste du, at 7 ud af 10 talenter ikke aktivt ansøger? Vi vil lære dig at bruge dit netværk bedre til at fange disse skjulte talenter."
      }
    },
    2: {
      en: "Trusted by companies across various industries and roles",
      no: "Stolt samarbeidspartner med selskaper innenfor en rekke bransjer og roller",
      dk: "Stolt partner med virksomheder inden for en række brancher og roller",
      subtitle: {
        en: "Sopra Steria, Ignite, Wilhelmsen, PwC, Brødrene Dahl. We have help fill roles from office managers to healthcare specialist, and even CTOs.",
        no: "Sopra Steria, Ignite, Wilhelmsen, PwC, Brødrene Dahl. Vi har hjulpet med å fylle roller fra kontorledere til helsepersonell og til og med CTOer.",
        dk: "Sopra Steria, Ignite, Wilhelmsen, PwC, Brødrene Dahl. Vi har hjulpet med at udfylde roller fra kontorledere til sundhedspersonale og endda CTOer."
      }
    },
    "3": {
      en: "Handle your entire referral program end-to-end.",
      no: "Håndter hele henvisningsprogrammet ditt fra start til slutt.",
      dk: "Håndter hele dit henvisningsprogram fra ende til anden.",
      subtitle: {
        en: "Share with your network, and get high-quality referrals.",
        no: "Del med nettverket ditt, og få høykvalitets henvisninger.",
        dk: "Del med dit netværk, og få henvisninger af høj kvalitet."
      }
    }
  },
  whyJoinVouch: {
    en: "Why join Vouch?",
    no: "Hvorfor bli med Vouch?",
    dk: "Hvorfor blive med Vouch?"
  },
  learnMore: {
    en: "Learn more about Vouch",
    no: "Lær mer om Vouch",
    dk: "Lær mere om Vouch"
  }
} satisfies PageContent;
export type AuthType = "default" | "business" | "acceptVouch";
type AuthScreenContext = {
  showAuth: AuthType | undefined;
  setShowAuth: (props: {
    type: AuthType | undefined;
    redirectUrl?: string;
    onClose?: () => void;
  }) => void;
};
const AuthScreenContext = React.createContext<AuthScreenContext>({} as AuthScreenContext);

// Just defaults to light for now
export function SignInProvider({
  children
}: ThemeProviderProps) {
  const [showAuth, setShowAuthVisible] = useState<"default" | "business" | "acceptVouch" | undefined>();
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const [onClose, setOnClose] = useState<(() => void) | undefined>();
  const session = useSession();
  const path = usePathname();
  const {
    lang
  } = useLocaleProvider();
  const {
    mutate: editUser
  } = trpc.user.edit.useMutation();
  const key = React.useMemo(() => {
    switch (showAuth) {
      case "business":
        return "business";
      case "acceptVouch":
        return "acceptVouch";
      default:
        return "default";
    }
  }, [showAuth]);
  useEffect(() => {
    if (session.status === "authenticated" && session.data.user) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has(SEARCH_PARAMS_NEWSLETTER_OPT_OUT)) {
        editUser({
          optOutNewsletter: true,
          firstName: session.data.user.firstName,
          lastName: session.data.user.lastName
        });
        const params = new URLSearchParams([...(searchParams?.entries() || [])]);
        params.delete(SEARCH_PARAMS_NEWSLETTER_OPT_OUT);
        // Ref https://github.com/vercel/next.js/discussions/48110
        window.history.replaceState({}, "", path + "?" + params.toString());
      }
    }
  }, [path, session, editUser]);
  const setShowAuth = React.useCallback(({
    type,
    redirectUrl,
    onClose: newOnClose
  }: Parameters<AuthScreenContext["setShowAuth"]>[0]) => {
    setRedirectUrl(redirectUrl);
    setShowAuthVisible(type);
    setOnClose(() => newOnClose);
  }, [setRedirectUrl, setShowAuthVisible]);
  const [email, setEmail] = useState<string>("");
  return <AuthScreenContext.Provider value={{
    showAuth,
    setShowAuth
  }} data-sentry-element="unknown" data-sentry-component="SignInProvider" data-sentry-source-file="SignInProvider.tsx">
      {<SimpleDialog open={!!showAuth} onClose={() => {
      onClose?.();
      setEmail("");
      setShowAuth({
        type: undefined
      });
    }} transparent wrapperClassName="md:max-w-4xl bg-background" className="scroll-smooth">
          <div className="flex w-full gap-8 py-4 max-md:flex-col-reverse">
            <div className="flex flex-1 items-center max-md:h-dvh" id="why">
              <div className="flex w-full flex-col justify-center gap-8 max-md:h-dvh max-md:px-8">
                <picture className="max-md:hidden">
                  <img src="/logo.svg" alt="" className="h-6 w-auto" />
                </picture>
                <Text.H5 className="md:hidden">{t.whyJoinVouch[lang]}</Text.H5>
                <div className="flex gap-2">
                  <Icon icon="check-circle" size="small" />
                  <div className="w-0 flex-1">
                    <Text.P strong>{t[key][1][lang]}</Text.P>
                    <Text.P muted>{t[key][1].subtitle[lang]}</Text.P>
                  </div>
                </div>

                <div className="flex gap-2">
                  <Icon icon="check-circle" size="small" />
                  <div className="w-0 flex-1">
                    <Text.P strong>{t[key][2][lang]}</Text.P>
                    <Text.P muted>{t[key][2].subtitle[lang]}</Text.P>
                  </div>
                </div>

                <div className="flex gap-2">
                  <Icon icon="check-circle" size="small" />
                  <div className="w-0 flex-1">
                    <Text.P strong>{t[key][3][lang]}</Text.P>
                    <Text.P muted>{t[key][3].subtitle[lang]}</Text.P>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col justify-start max-md:h-[calc(100vh-100px)] max-md:justify-center md:max-w-sm">
              <div className="w-full rounded-xl bg-white p-8">
                {email ? <VerifyingAuthScreen email={email} lang={lang} /> : <div className="space-y-4">
                    <picture className="md:hidden">
                      <img src="/logo.svg" alt="" className="h-6 w-auto" />
                    </picture>
                    <Text.H5>{t.logInOrSignUp[lang]}</Text.H5>
                    <SignIn onVerifyOpen={newEmail => {
                setEmail(newEmail);
              }} onSignUpClicked={() => Promise.resolve({
                callbackUrl: redirectUrl,
                abort: false
              })} />
                  </div>}
              </div>
              <Link href="#why" className="ml-8 mt-4 flex items-center md:hidden">
                <Text.P muted>{t.learnMore[lang]}</Text.P>
                <Icon size="x-small" icon="downChevron" variant="gray" />
              </Link>
            </div>
          </div>
        </SimpleDialog>}
      {children}
    </AuthScreenContext.Provider>;
}
export const useAuth = () => React.useContext(AuthScreenContext);